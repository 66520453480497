import React from "react";
import "./about.scss";
import img from '../../assets/208.png'
import {MdOutlineContactPage} from 'react-icons/md'
import CV from '../../assets/DinhTanMai.pdf'

export default function About() {
  return (
    <div id="about" className="about">
      <div className="container">
        <h2>ABOUT ME</h2>
        <div className="about_main">
          <img src={img} alt="" />
          <div className="about_main_text">
            <h4>A dedicated Front-end Developer based in Tra Vinh, Viet Nam ❤️</h4>
            <span>
              As a Junior Front-End Developer, I possess an impressive arsenal
              of skills in HTML, CSS, JavaScript, React, Tailwind, and SCSS. I
              excel in designing and maintaining responsive websites that offer
              a smooth user experience. My expertise lies in crafting dynamic,
              engaging interfaces through writing clean and optimized code and
              utilizing cutting-edge development tools and techniques. I am also
              a team player who thrives in collaborating with cross-functional
              teams to produce outstanding web applications.
            </span>
            <a href={CV} download>Download CV <MdOutlineContactPage/></a>
          </div>
        </div>
      </div>
    </div>
  );
}
