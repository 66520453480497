import React, { useEffect, useState } from "react";
import "./reviews.scss";
import img from "../../assets/IMG_344343.jpg";
import data from "../../data/Reviews.json";

export default function Reviews() {
  const [reviews, setReviews] = useState([]);

  useEffect(() => {
    setReviews(data);
  }, []);

  return (
    <div className="reviews">
      <div className="container">
        <h4 className="title">REVIEWS 🧾</h4>
        <span className="desc">
          Each project is a unique piece of development 🧩
        </span>
        <div className="reviews_main">
          <div className="reviews_main_list">
            {reviews &&
              reviews.map((review, i) => (
                <div key={i} className="reviews_main_list-item">
                  <span>
                    {review.desc}
                  </span>
                  <div className="info">
                    <img src={review.avt} alt="" />
                    <div className="name">
                      <p>{review.name}</p>
                    </div>
                  </div>
                </div>
              ))}
          </div>
        </div>
      </div>
    </div>
  );
}
