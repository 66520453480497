import React from "react";
import './contact.scss'
import { IoIosMailOpen } from "react-icons/io";

export default function Contact() {
  return (
    <div id="contact" className="contact">
      <h4 className="title">CONTACT 📞</h4>
      <span className="desc">Don't be shy! Hit me up! 👇</span>
      <div className="contact_main">
        <div className="icon"> 
          <IoIosMailOpen />
        </div>
        <div className="contact_main_body">
          <h4>Mail</h4>
          <a href="mailto:dinhtanmaivn@gmail.com">dinhtanmaivn@gmail.com</a>
        </div>
      </div>
    </div>
  );
}
