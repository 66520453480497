import React, { useEffect, useState } from "react";
import "./header.scss";
import { HiOutlineLogout } from "react-icons/hi";
import { HiMenuAlt3 } from "react-icons/hi";
import { GrFormClose } from "react-icons/gr";
import { Link } from "react-router-dom";
import { useMode } from "../../context/ModeContext";
import { useAuth } from "../../context/AuthContext";
import { signOut } from "firebase/auth";
import { auth } from "../../utils/firebase";
import { useLocation } from "react-router-dom";

export default function Header() {
  const { toggleDarkMode, darkMode } = useMode();
  const [menu, setMenu] = useState(false);

  const {hash} = useLocation()

  const handleChangeMenu = () => {
    setMenu(!menu)
  }

  useEffect(() => {
    setMenu(false)
  },[hash])

  return (
    <div className="header">
      <div className="header_left">
        <a href="/">
          <img src="" alt="" />
          <h2>TanmaiDev</h2>
        </a>
      </div>
      <div className={menu ? "header_right active" :  "header_right"}>
        <ul>
          <li>
            <a href="#">Home</a>
          </li>
          <li>
            <a href="#about">About</a>
          </li>
          <li>
            <a href="#projects">Projects</a>
          </li>
          <li>
            <a href="#contact">Contact</a>
          </li>
        </ul>
        <button
          onClick={() => toggleDarkMode()}
          className={darkMode ? "darkmode dark" : "darkmode"}
        >
          <img
            className="sun"
            src="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAADAAAAAwCAYAAABXAvmHAAAACXBIWXMAAAsTAAALEwEAmpwYAAABsUlEQVR4nO2ZzU7CQBSFJ8TUN9CFuMcVpobQxAdzKUvfgIlGA76E7uARZCkYSFhUkz7EMddWLWM7xeH2Z8jc5CQESjlfO733TBDClStXrva2MBQDDMWNsNa8FPiSbRBIm7cNAlnmbYGAznwTIFBkjknCAeRU4dV7OACej4HZGbD0gY8gFr2edYCno/iYxt2BuxYwbQNhAESXetExk3b8HdkEgJEHzLvFxlW9nQOPhzUDjD1g3fu/+W+te/E5agGgJbAwuPKq5l3gtlUDwPR0d/NRInomygbYGFLUSbZ5YLdVGGx2J+5h92fCUqvkMh8lohZbxsTOjAfU57kBXjr8sSM326wu+AGWPm920gazd8b1HyWic3IGQEhxVSlAqAGQ4trsLuRBrPzqlpA0NK+FoGBWxUMsdzSfC1FFG5VM5jMh2AdZX43ZvOZTEL8/QuOfC2ByUlOYM4nRql7rCnNccXrkWbqhWTRhQyNNtpT9+PlRlg1qBUh3J2qHNCdoMNHEJtFr6vP02X0TN/WSV8IBGJY2AJY9pCqCaLb5Agg7zOdA2GVe+Ytp8POGK1euXO1dfQIP3ciBFRe34QAAAABJRU5ErkJggg=="
          />
          <img
            className="moon"
            src="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAADAAAAAwCAYAAABXAvmHAAAACXBIWXMAAAsTAAALEwEAmpwYAAAEmUlEQVR4nO2Zz08bRxTHrSpSDk3+hTaRqrRSo/S/qHIIp1659YcqVVWI2TUGDGsbJH7aYGx+Gb9Zi0PBOUBABmPtm1rqpScS1CY55lCp6iEkElRVm5Bmq7fyWuvFxuPx2s3BT3rSsrvMfD8z78cs+Hxd61rXukaWTqevA8A3ALAJAD8zxp4xxv5ijL0GgJeMsSMAuA8A9wDgM9+7YIlE4rKu618zxp4yxswm/QljzJ9Kpa50XLimae/puv49APwhIbzKAeAYAPo0TbvUEfG6rl8DgFKrwmv4w0wm83FbxUcikS8A4KQN4s2ynzLGetoiXlGU3nA4/Myx9eb8/LwZjUbNoaEhU1VVMxAImKFQyJyamrKeS0KcAUCvp+L7+/t7FEV5Mzc395YmWV5eNkdGRizR9XxiYqKVnTgDgDueiPf7/TcURTklUaurq2YymbRW+iLx5MFg0Jmo5uTkpHWPfHx83Eyn040g/tR1/ZOWxFNlUBTl0Ba1sLAgJJ58cHCwImZ6evrccwo1aBBmAHDYUnVSFKVPRGwtJ9G2EBJb653Z2dlzoin0aJcceXRXSryqqlcVRTmWET86Olq1uvV2LRQKnQMYGBioPI/H41afyGQyV2VWX5Fd/ZWVlar4v+jdeDxeBTA8PFx5Rtfl+34ZgKeyAGtraxVBBNPo/Wg0WknqWCxWuU+7YR87mhV/yzlBOBy2XBTAFkRO1yK/EwwGzaWlJWvHKH+oCFDlshcik8ncbAbALwJADSwSiVgiaULZHVMdEM7dc7l4MquqmhNd6bGxMcsJpFUAVVWtHlGrvNIxXRggEAg88QqAKhAJODg4MLPZrBBEJBKxur0L4JEwgKZpb2oNTMcHEm3XdQob+pkmrBdCuq6bnHPLEVG6lzDGngsDxGKxf2sNaK+2aGLSYc8Wb3szuRJ0HEcYY/8IA2Sz2bNaA9LKE0C9zuo8clDIuMWTU6cVBQhVNzpxgEKh8ILoZROxnnjyzc3Nmp05mUxayZtIJKzqRvPTtVQIcc5/WVxcbAsAOQl1HhlUVRU5nR41A3CfRNCZRgZgY2PjQgDyfD5f1XU1TbOO6/UAdF1/IAxgGIafJtne3q46m4j6zMxMQwC7Ko04PowodOqIN3d2dqaFARDxlnOlqJo0A0JCRAB4OSdopymkqIm5xVPvyOfzbw3D+EAYoAzxq3uyra0tIQAqlaIA3OGFQsHM5XLm+vq6JZyui8UiPTtsSrwzjGTqOJ2bZAB4/VD7rmmAYrH4PiI+dw9GX1GNAFKplJcAv+3v71/2yRgi9rkH3N3dvTAfqJo0KqNNrv63PlkrlUqXOOcP3YPu7e1Z3ZaSj5oSAVEC0seLl+I55z+RBmmAMsRHnPMTL2NacOVfcs4/9HlhiNiDiGcdBHhlGMZtT8Q7IHo7AYGIfxuG4c1f5dxGAyPiaRsBTjjnn7dFvAPiBjWWNoj/0bOYb2RUGRDxLuf82APhv1Ojon+adES8C+QKdWxEfCwR648R8UvpJuW1cc4/pV1BxBwiPuKcv6BqgoivqaMj4hEi/oCIX5VKpWv/t96uda1rvnfD/gM0dKahps3mYgAAAABJRU5ErkJggg=="
          />
        </button>
        <button className="close" onClick={handleChangeMenu}>
            <GrFormClose/>
        </button>
      </div>
      <div className="header_menu">
        <button onClick={handleChangeMenu}>
          <HiMenuAlt3 />
        </button>
      </div>
    </div>
  );
}
