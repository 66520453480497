import React from "react";
import {
  Routes as RoutesSwitch,
  Route,
  Outlet,
  Navigate,
} from "react-router-dom";
import Home from "../pages/home/Home";

export default function Routes() {

  return (
    <RoutesSwitch>
      <Route path="/" Component={Home} />
    </RoutesSwitch>
  );
}
