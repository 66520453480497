import React, { useEffect, useState } from "react";
import "./projects.scss";
import { BsGithub } from "react-icons/bs";
import { AiFillEye } from "react-icons/ai";
import img from "../../assets/OT12YY1.jpg";
import data from "../../data/Project";
import { motion, AnimatePresence } from "framer-motion";

const genres = [
  { value: "all", name: "All" },
  { value: "web", name: "Web" },
  { value: "app", name: "App" },
  { value: "desgin", name: "Desgin" },
];

export default function Projects() {
  const [projects, setProjects] = useState([]);
  const [filter, setFilter] = useState();
  const [active, setActive] = useState("all");

  useEffect(() => {
    if (active === "all") {
      setFilter(data);
      return;
    }
    const filtered = data.filter((project) => {
      return project.category.includes(active);
    });
    setFilter(filtered);
  }, [active]);

  const handleChangeActive = (name) => {
    setActive(name);
  };

  return (
    <div id="projects" className="projects">
      <h4 className="title">PROJECTS</h4>
      <span className="desc">
        Each project is a unique piece of development 🧩
      </span>
      <div className="projects_control">
        <ul>
          {genres.map((item, i) => (
            <li key={i} className={active === item.value ? "active" : ""}>
              <button onClick={() => handleChangeActive(item.value)}>
                {item.name}
              </button>
            </li>
          ))}
        </ul>
      </div>
      <div className="projects_main">
        <div className="projects_main_list">
          <AnimatePresence>
            {filter &&
              filter.map((project, i) => (
                <motion.div
                  layout
                  animate={{ opacity: 1 }}
                  initial={{ opacity: 0 }}
                  exit={{ opacity: 0 }}
                  transition={{ duration: 0.3 }}
                  key={i}
                  className="projects_main_item"
                >
                  <div className="projects_main_item-main">
                    <div className="projects_main_item-img">
                      <img src={project.img} alt="" />
                    </div>
                    <div className="projects_main_item-body">
                      <h5>{project.title}</h5>
                      <span className="desc">{project.desc}</span>
                      <div className="language">
                        {project.language.map((item, i) => (
                          <span key={i} className={item.class}>
                            {item.name}
                          </span>
                        ))}
                      </div>
                      <div className="link">
                        <a href={project.linkGit}>
                          <button>
                            Code <BsGithub />
                          </button>
                        </a>
                        <a href={project.linkDemo}>
                          <button>
                            Demo <AiFillEye />
                          </button>
                        </a>
                      </div>
                    </div>
                  </div>
                </motion.div>
              ))}
          </AnimatePresence>
        </div>
      </div>
    </div>
  );
}
