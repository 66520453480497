import React from "react";
import Header from "../../components/header/Header";
import Hero from "../../components/hero/Hero";
import About from "../../components/about/About";
import Projects from "../../components/projects/Projects";
import Reviews from "../../components/reviews/Reviews";
import Contact from "../../components/contact/Contact";
import "./home.scss";
import {BsFacebook,BsGithub} from 'react-icons/bs'
import { Link } from "react-router-dom";


export default function Home() {
  return (
    <div className="home">
      <Header />
      <div className="content">
        <div className=" container">
          <Hero />
        </div>
        <About />
        <div className=" container">
          <Projects />
        </div>
        <Reviews />
        <div className=" container">
          <Contact />
        </div>
      </div>
      <div className="bottom">
        <div className="container">
          <div className="bottom-left">
            <span>Copyright © 2023. All rights are reserved</span>
          </div>
          <div className="bottom-right">
            <a href="https://www.facebook.com/tanmai2003">
              <BsFacebook/>
            </a>
            <a href='https://github.com/tanmaiii'>
              <BsGithub/>
            </a>
          </div>
        </div>
      </div>
    </div>
  );
}
