import Home from './pages/home/Home'
import './app.scss'
import {useMode} from './context/ModeContext'
import Routes from './config/Routes'

function App() {
  const {darkMode} = useMode()

  return (
    <div className={`theme-${darkMode ? "dark" : "light"}`}>
        <Routes/>
    </div>
  );
}

export default App;
