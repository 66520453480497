import { initializeApp } from "firebase/app";
import { getAnalytics } from "firebase/analytics";
import { getAuth } from "firebase/auth";
import { getStorage } from "firebase/storage";
import { getFirestore } from "firebase/firestore";

const firebaseConfig = {
  apiKey: "AIzaSyD2w8a_m_c0GGzT-abGC1NAWp3dCN7zTn4",
  authDomain: "portfolio-af9ef.firebaseapp.com",
  projectId: "portfolio-af9ef",
  storageBucket: "portfolio-af9ef.appspot.com",
  messagingSenderId: "422662639184",
  appId: "1:422662639184:web:60a5d1c33f93459df30b55",
  measurementId: "G-NN3XX2JQXC"
};

const app = initializeApp(firebaseConfig);
export const auth = getAuth(app);
export const storage = getStorage();
export const db = getFirestore();
