import React from "react";
import "./hero.scss";
import img from "../../assets/IMG_344343.jpg";
import { BsFacebook, BsGithub } from "react-icons/bs";
import { Link } from "react-router-dom";
import { motion } from "framer-motion";
import language from "../../data/Language.json";

export default function Hero() {
  return (
    <div className="hero">
      <div className="hero_main">
        <div className="hero_main_text">
          <motion.h2
            initial={{ opacity: 0, scale: 0.5 }}
            animate={{ opacity: 1, scale: 1 }}
            transition={{ duration: 0.5 }}
          >
            Front-End React Developer ✌️
          </motion.h2>
          <motion.span
            initial={{ opacity: 0, scale: 0.5 }}
            animate={{ opacity: 1, scale: 1 }}
            transition={{ duration: 0.5 }}
          >
            Hi, I'm Tan Mai. A passionate Front-end React Developer based in Tra
            Vinh, Viet Nam.📍
          </motion.span>
          <div className="link">
            <a href="https://www.facebook.com/tanmai2003/">
              <BsFacebook />
            </a>
            <a href="https://github.com/tanmaiii">
              <BsGithub />
            </a>
          </div>
        </div>
        <motion.div
          initial={{ opacity: 0, scale: 0.5 }}
          animate={{ opacity: 1, scale: 1 }}
          transition={{ duration: 0.5 }}
          className="hero_main_img"
        ></motion.div>
      </div>
      <div className="hero_language">
        <h4>Tech Stack</h4>
        {/* <div className="hero_language_list">
          <div className="hero_language_item">
            <img src={html} alt="" />
          </div>
          <div className="hero_language_item">
            <img src={css} alt="" />
          </div>
          <div className="hero_language_item">
            <img src={js} alt="" />
          </div>
          <div className="hero_language_item">
            <img src={react} alt="" />
          </div>
          <div className="hero_language_item">
            <img src={sass} alt="" />
          </div>
          <div className="hero_language_item">
            <img src={node} alt="" />
          </div>
        </div> */}
        <div className="hero_language_list">
          {language &&
            language.map((item, i) => (
              <div className="hero_language_item">
                <img src={item.base64} alt="" />
              </div>
            ))}
        </div>
      </div>
    </div>
  );
}
